import React from "react";

import {Col, Container, Row} from "react-bootstrap";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import Statistics from "../../components/Statistics";
import Questions from "../../components/Questions";
import WeAreCTA from "../../components/WeAreCTA";
import Button from "../../components/Button";
import MotifColumn from "../../components/MotifColumn"

import styles from "./index.module.scss";
import OurServices from "../../components/OurServices";


const ServicesPage = () => {
    return (
        <Layout>
            <Background themeType={"oanda"}>
                <Hero backgroundType={"dark"} type={"business"}>
                    <MotifColumn>
                        <span className={styles.subHeading}>Shift Connect</span>
                        <h1 className={styles.heading}>Our Services</h1>
                        <p>At Shift Connect we offer a variety of services to both corporate and private clients.
                            Helping you easily navigate the complex world of forex and global payments, our team of
                            experts is ready to support you. See below to learn more about our offerings. We’re looking forward to
                            working with you.</p>
                    </MotifColumn>
                </Hero>

                <Container class="container-fluid ">
                    <Row class="row full-width-row">
                        <Col className={styles.firstSection} xs={12}>
                            <h1>Joining the global community.</h1>
                            <p>
                                If you send or receive money internationally, we have a solution for you. Serving a
                                diverse client base of individuals and businesses alike, we are passionate about saving
                                you time and money. When you join Shift Connect’s global community, you are backed by a
                                whole team of experienced foreign exchange professionals with decades of industry
                                experience.
                            </p>
                            <Button
                                to="/contact"
                                className={styles.button}
                            >
                                Get in Touch
                            </Button>
                        </Col>
                    </Row>
                </Container>
                <OurServices/>
                <WeAreCTA/>
                <Statistics themeType={"dark"}/>
                <Questions/>
            </Background>
        </Layout>
    );
};

export default (ServicesPage);
